@use 'sass:math';

@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}


// extra small
@mixin xs-375() {
  @media only screen and (max-width: #{$sm-phone-width}) {
    @content;
  }
}

// extra small
@mixin xs() {
  @media only screen and (max-width: #{$phone-width}) {
    @content;
  }
}

// small
@mixin sm() {
  @media only screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

// medium
@mixin md() {
  @media only screen and (max-width: #{$sm-desktop-width}) {
    @content;
  }
}

// large
@mixin lg() {
  @media only screen and (max-width: #{$desktop-width}) {
    @content;
  }
}

// large
@mixin lg-1400() {
  @media only screen and (max-width: #{$desktop-width-1400}) {
    @content;
  }
}

$sides: (
  "top": "t",
  "right": "r",
  "bottom": "b",
  "left": "l",
);

// @each $prop, $letter in $sides {
//   @for $px from 0 through 20 {
//     .m#{$letter}-#{$px * 4} {
//       margin-#{$prop}: #{$px * 4}px !important;
//     }

//     .p#{$letter}-#{$px * 2} {
//       padding-#{$prop}: #{$px * 4}px !important;
//     }
//   }
// }

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin hide-scroll() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return #{math.div($pixels, $context)}em;
}

@mixin font($font-size, $line-height: false, $font-weight: false) {
  font-size: em($font-size);
  /* #{$font-size} */

  @if $line-height !=false {
    line-height: $line-height;
  }

  @else {
    line-height: 1.5;
  }

  @if $font-weight !=false {
    font-weight: $font-weight;
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin hidden() {
  display: none !important;
  visibility: hidden !important;
}

@mixin line-clamp($count) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $count;
  line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}