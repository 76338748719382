.last-news {
  background-color: $primary;
  padding-top: 40px;
  padding-bottom: 48px;
  overflow: hidden;
  margin-top: 74px;

  @include md() {
    margin-top: 32px;
  }

  a {
    color: $neutral-100;
  }

  &__title {
    text-transform: uppercase;
    @include font(20, 24px, 400);
    margin-bottom: 32px;

    &-mob {
      display: none;

      @include sm() {
        display: block;
      }
    }
  }

  &__items {
    display: none;

    @include sm() {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr 3fr;
      gap: 32px;
      grid-template-columns: 1fr;
    }
  }

  .swiper {
    width: 100vw;
    position: static;
  }

  .container {
    position: relative;
  }

  .swiper-slide {
    width: 304px;
    user-select: none;
  }

  &__navigation {
    display: flex;
    position: absolute;
    right: 30px;
    z-index: 0;
    top: 0;

    @include sm() {
      right: 16px;
    }
  }

  .last-news-btn-prev {
    margin-right: 16px;
  }

  .last-news-btn-prev,
  .last-news-btn-next {
    cursor: pointer;
  }

  &__item {
    &-date {
      @include font(14, 10.8px, 400);
      width: fit-content;
      font-family: 'Montserrat';
      padding: 3px 7px;
      background-color: $neutral-100;
      color: $primary;
      border-radius: 16px;
    }

    &-mob-date {
      @include font(14, 24px, 400);
      margin-top: 8px;
      color: $neutral-100;
    }

    &-title {
      &__items {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        gap: 32px;

        @include md() {
          grid-template-columns: 6fr 6fr;
        }

        @include xs() {
          grid-template-columns: 1fr;
        }
      }

      color: $neutral-100;
      @include font(20, 24px, 400);
      display: inline;
      position: relative;
      top: 1.5px;

      @include sm() {
        line-height: 28px;
      }
    }
  }

  &__arrow-link {
    display: flex;
    margin-top: 32px;
    align-items: center;
    justify-content: flex-start;
    @include font(16, 24px, 700);

    svg {
      margin-left: 16px;
    }
  }
}

.last-news-slider {
  padding-right: 15vw;

  @include lg-1400 {
    padding-right: 5vw;
  }
}

.bottom-last-news {
  margin-top: 40px;
  overflow: hidden;

  @include sm {
    margin-top: 32px;
  }

  .badge {
    margin-left: 0.5px;
    margin: 16px 0
  }

  .swiper {
    @include sm {
      width: 100vw;
      position: static;
    }
  }

  &__item {
    height: auto;
    display: flex;
    flex-direction: column;

    @include sm {
      max-width: 320px;
    }

    &-title {
      @include font(18, 21.6px, 700);
      color: $neutral-100;

      @include sm {
        @include font(16, 22.4px);
      }
    }

    &-img {
      @include size(100%, auto);
      max-height: 169px;
      object-fit: cover;

      @include sm {
        max-height: 180px;
      }
    }
  }
}