@import "../utils/variables";
@import "../utils/mixins";
@import "../sections/last-news";

.top-banner {
  @include size(100%, 100%);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--neutral-100, #333);
  transition: all 0.3s linear;
  max-height: 350px;

  &__data {
    min-height: 252px;
    padding-top: 24px;
    padding-bottom: 40px;
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include sm {
      min-height: 280px;
      padding-bottom: 24px;
    }

    .badge {
      background-color: $primary;
      border-color: $primary;
      width: fit-content;
      margin-bottom: 24px;
    }
  }

  &__image {
    @include size(100%, 100%);
    background-position: 0 28% !important;
    position: absolute;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    top: 0;
    z-index: 0;

    @include sm {
      background-position: center !important;
    }
  }

  &__title {
    color: $primary;
    @include font(32, 38.4px, 900);
    margin-top: auto;
    text-align: center;

    @include sm {
      @include font(20, 24px);
    }
  }
}

.hero {
  padding-top: 64px;
  overflow: hidden;

  @include md() {
    padding-top: 24px;
    padding-bottom: 0;
  }


  >div {
    display: grid;
    grid-template-columns: 2.83fr 6fr 2.83fr;
    gap: 36px;

    @include md() {
      display: grid;
      grid-template-areas: 'b' 'c' 'a';
      grid-template-columns: 1fr;

      >div {
        &:nth-child(1) {
          grid-area: a;

          @include sm {
            margin-top: 24px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: 'aa' 'ab' 'ac' 'ad';

            >div {
              &:nth-child(1) {
                grid-area: ac;
              }

              &:nth-child(2) {
                grid-area: ab;
              }

              &:nth-child(3) {
                grid-area: aa;
              }

              &:nth-child(4) {
                grid-area: ad;
              }
            }
          }
        }

        &:nth-child(2) {
          grid-area: b;
        }

        &:nth-child(3) {
          grid-area: c;

          @include sm {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-areas: 'aa' 'ab' 'ac' 'ad';

            >div {
              &:nth-child(1) {
                grid-area: aa;
              }

              &:nth-child(2) {
                grid-area: ac;
              }

              &:nth-child(3) {
                grid-area: ab;
              }

              &:nth-child(4) {
                grid-area: ad;
              }
            }
          }
        }
      }
    }

    @include sm() {
      gap: 0;
    }

  }

  &__text-item {
    padding: 24px 0;
    margin: 24px 0;
    border-bottom: solid 1px $neutral-100;
    border-top: solid 1px $neutral-100;

    h3 {
      margin-bottom: 0;
    }

    @include md {
      margin: 0;
    }

    @include sm {
      border-top: none;
      padding-top: 0;
    }
  }

  &__content {
    color: $neutral-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 636px;
    margin: 0 auto;

    &:hover {
      h1 {
        color: #F26106;
      }

      img {
        mix-blend-mode: normal;
      }
    }

    a {
      color: $neutral-100;
    }

    @include md() {
      padding-bottom: 24px;
    }

    img {
      object-fit: cover;
      width: 100%;
      max-height: 488px;
      // mix-blend-mode: luminosity;
      border: solid 2px $neutral-100;

      @include md() {
        height: auto;
      }

      @include sm() {
        min-height: 290px;
      }
    }
  }

  &__title {
    @include font(36, 46.8px, 900);
    padding-bottom: 24px;
    transition: color 0.2s ease-in-out;

    @include md() {
      @include font(24, 31.2px);
      padding-bottom: 12px;
    }
  }

  &__author {
    @include font(14, 14px, 400);
    margin-bottom: 36px;

    @include md() {
      margin-bottom: 25px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;

    .sidebar-bg-image-item {
      @include sm {
        min-height: 318px;
      }
    }


    // >div {
    //   height: 100%;
    // }

    @include md() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;

      @include sm {
        grid-template-columns: 1fr;
      }
    }

    &-mob {
      display: none;

      @include md() {
        display: flex;
        gap: 32px;

        >div {
          min-width: 203px;
          max-width: 203px;
        }

        img {
          width: 100%;
          display: block;
          max-height: 144px;
        }
      }
    }
  }

  &_colourful {
    img {
      mix-blend-mode: normal;
    }
  }

}

.category-info {
  overflow: hidden;
  color: $neutral-100;
  text-align: center;
  margin-top: 64px;

  @include md() {
    margin-top: 40px;
  }

  &__title {
    margin: 0 auto;
    display: flex;
    margin-bottom: 43px;
    padding-top: 2px;
    color: $neutral-100;
    width: fit-content;
    @include font(32, 38.4px, 400);

    @include md() {
      margin-bottom: 16px;
    }

    @include sm {
      @include font(24, 28.8px);
    }

    svg {
      margin-left: 12px;
      position: relative;
      bottom: 0;
      left: 0;
      transition: 0.2s ease-in-out;
    }

    &:hover {
      svg {
        bottom: -3px;
        left: -3px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 48px 24px;
    height: 519px;
    background-color: $neutral-40;
    border-bottom: solid 2px $neutral-100;
    position: relative;

    @include sm {
      height: 477px;
      padding-bottom: 41px;
    }

    a {
      color: $neutral-100;
      display: block;
      transition: color 0.2s ease-in-out;
    }

    &-title {
      @include font(32, 38.4px, 900);

      @include sm() {
        @include font(20, 24px);
      }
    }

    &-author {
      margin-top: 40px;
      @include font(14, 14px 400);

      @include sm {
        margin-top: 33px;
      }
    }

    .badge {
      border: none;
    }

    &-data {
      position: relative;
      z-index: 2;
      @include center-flex();
      flex-direction: column;
      color: $primary;
      min-height: 131px;
      justify-content: space-between;

      a {
        color: $primary;
      }
    }

    .badge {
      position: relative;
      z-index: 2;
    }
  }

  // TODO: Remove Later
  &__star-decor {
    position: absolute;
    top: 8px;
    left: -40px;
    z-index: 0;
  }

  &__bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    @include size(100%, 100%);

    >img {
      @include size(inherit, inherit);
      object-fit: cover;
    }

    &::before {
      content: '';
      @include size(100%, 100%);
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.288) 66.67%, rgba(0, 0, 0, 0.6) 100%);

    }
  }
}

.top-news-section {
  padding-top: 80px;
  padding-bottom: 138px;
  background-color: #151515;
  overflow: hidden;

  @include sm() {
    padding-bottom: 96px;
  }

  >div {
    padding-right: 0;
    position: relative;
  }

  &__title {
    text-transform: uppercase;
    @include font(20, 24px, 400);
    color: $white;

    @include sm() {
      line-height: 28px;
    }
  }

  .grid-item {
    border: none;
    border-bottom: solid 2px $neutral-100;
    overflow: visible;
    height: auto;
    padding: 24px;

    img,
    image {
      filter: grayscale(1);
      transition: filter 0.1s ease;
    }

    &:hover {

      img,
      image {
        filter: grayscale(0);
      }
    }

    @include sm() {
      padding-bottom: 16px;
    }

    @include sm() {
      max-height: 100%;
      min-height: 328px;
    }

    .grid-item__title {
      max-width: 280px;

      @include xs() {
        max-width: 264px;
      }
    }

    &_centered-item {
      max-height: fit-content;

      .grid-item__title {
        span {
          border-radius: 144px;
          background-color: $primary-orange;
          -moz-border-bottom-left-radius: 144px;
          -webkit-border-bottom-left-radius: 144px;
          border-bottom-left-radius: 144px;
          -moz-border-bottom-right-radius: 144px;
          -webkit-border-bottom-right-radius: 144px;
          border-bottom-right-radius: 144px;
          -moz-border-top-right-radius: 144px;
          -webkit-border-top-right-radius: 144px;
          border-top-right-radius: 144px;
          -webkit-box-decoration-break: clone;
          -o-box-decoration-break: clone;
          box-decoration-break: clone;
        }
      }
    }

    &:hover {
      img {
        transform: scale(1);
      }
    }
  }

  .grid-item__star-img {
    right: 0;
    top: 0;
  }

  .grid-item__star-mob {
    display: none;

    @include sm() {
      display: block;
    }
  }

  .grid-item_image-color {
    border: solid 2px #F26106;
  }

  &__step-item {
    position: absolute;
    left: -35px;
    top: -50px;
    color: $neutral-100;
    z-index: 100;

    span {
      font-family: 'SFProText';
      @include font(24, 28px, 700);
      position: absolute;
      left: 26px;
      top: 28.5px;
      z-index: 10;
    }

    svg {
      path {
        fill: #F26106
      }
    }
  }

  .swiper {
    padding-top: 76px;
    padding-left: 40px;
    margin-left: -40px;
    position: initial;
    width: 100vw;
    padding-right: 584px;

    @include md() {
      padding-right: 16px;
    }

    @include xs() {
      padding-right: 16px;
    }

    @include sm() {
      width: auto;
      padding-top: 56px;
    }
  }

  .swiper-slide {
    max-width: 412px;
    width: 412px;

    @include sm() {
      max-width: 328px;
    }
  }

  .swiper-slide-prev {
    margin-left: -8px;

    @include lg-1400() {
      margin-left: 0;
    }

    @include sm() {
      margin-left: 16px;
    }

    @include xs() {
      margin-left: 8px;
    }
  }

  &__decorations {
    img {
      position: absolute;

      &:first-child {
        top: -7px;
        left: -10px;

        @include sm() {
          left: -5px;
        }
      }

      &:last-child {
        left: 228px;
        top: -40px;

        @include sm() {
          left: 112px;
        }
      }
    }
  }

  .arrow-navigation {
    top: 10px;

    @include xs() {
      display: none;
    }
  }
}