// Global params

$os-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-primary: "HeliosExt"$os-font !default;
$font-weight-primary: 400 !default;

// Media
$desktop-width-1400: 1400px;
$desktop-width: 1200px;
$sm-desktop-width: 1024px;
$tablet-width: 768px;
$sm-tablet-width: 640px;
$phone-width: 500px;
$sm-phone-width: 375px;

// Main Colors
$text-color: #333333;
$white: #ffffff !default;
$body: #F2F2F2;
$primary: #D8FA6F;
$primary-purple: #CBBAED;
$primary-platinum: #E3E3E3;
$primary-green: #C9FCC5;
$primary-orange: #F26106;

// Neutral Colors
$neutral-30: #EBEBEB;
$neutral-40: #E6E6E8;
$neutral-50: #CECECE;
$neutral-60: #B1B1B1;
$neutral-70: #919191;
$neutral-80: #818181;
$neutral-90: #686868;
$neutral-100: #333333;